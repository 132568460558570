import { ContactsInterface, } from '@/store/types/Business/Contacts'

export const ContactsForm: ContactsInterface[] = [
  {
    id: 1,
    name: '',
    last_name: '',
    email: '',
    phone: '',
    position: 'main',
    disabled: true,
    title: 'Responsable Principal',
  },
]
