
import { Component, Vue } from 'vue-property-decorator'
import { ContactsInterface } from '@/store/types/Business/Contacts'
import { CrudBusiness } from '@/store/types/Business/CrudBusiness'
import * as validations from '@/utils/Validations'
import { ContactsForm } from '@/utils/Contacts'
import InputHackmetrix from '@/components/InputHackmetrix/Index.vue'
import SelectHackmetrix from '@/components/SelectHackmetrix/Index.vue'
import { FileUploadInterface } from '@/store/modules/dataroom/dataroomModule'
import { DataroomTemplate } from '@/store/types/Dataroom/DataroomTemplate'
import { mapGetters } from 'vuex'
import {
  Architecture,
  Documentation,
  Normative,
  SelectInterface,
  Channels,
  TypeSaq,
} from '@/utils/Select'
import ChipHackmetrix from '@/components/Chip/Index.vue'
import OverlayBackground from '@/components/OverlayBackground/Index.vue'

@Component({
  components: {
    InputHackmetrix,
    SelectHackmetrix,
    ChipHackmetrix,
    OverlayBackground,
  },
  computed: {
    ...mapGetters('PolimorficModule', ['getFramework', 'getFrameworkSelected', 'getSaq']),
    ...mapGetters('PolimorficModule', { frameworkSelected: 'getFrameworkSelected' }),
  },
})
export default class EditBusiness extends Vue {
  private business = this.$store.state.BusinessModule.BusinessState.businessSelected
  private name = this.business.name
  private frameworkSelected: any
  private normative: SelectInterface[] = Normative
  private description = this.business.description
  private documentation_level = this.business.documentation_level
  private documentation: SelectInterface[] = Documentation
  private arcuitecture_options: SelectInterface[] = Architecture
  private messageInfo = 'Campo requerido'
  private architecture = this.business.architectures
  private architecture_description = this.business.architecture_description
  private framework_selected: number[] = []
  private architecture_selected: string[] = []
  private framework_ids: number[] = [1]
  private saq_id: SelectInterface[] = TypeSaq
  private contacts: ContactsInterface[] = ContactsForm
  private contactsToForm: ContactsInterface[] = []
  private validator = validations
  private businessAvailable = true
  private frameworksAvailable = true
  private channel_options: SelectInterface[] = Channels
  private channel_selected = this.business.channels
  private kickoff = this.business.kickoff_url
  private minutaName = ''
  private minutaUrl = ''
  private fileToUpload: FileUploadInterface[] = []
  private datarooms: string[] = []
  private dataroomsName: string[] = []
  private files: DataroomTemplate[] = []
  private loaderUpload = false
  private uploaded = false
  private saq = false
  private indexFramework = this.$store.state.PolimorficModule.PolimorficState.frameworks.data
  private itemSaq = this.indexFramework[0].saqs
  private saq_selected: any[] = []
  private domains: string[] = this.business.domains
  private newDomain = ''
  private wrongUrlMessage = 'Formato de dominio incorrecto'
  private isWrongUrl = false
  private loading = false
  private hubspot_id: number = this.business.hubspot_id
  public hubspot_id_available: boolean = true
  public hubspot_id_is_number: boolean = true
  mounted() {
    this.$store.dispatch('PolimorficModule/getFrameworks')
    if (this.kickoff.length > 0 || this.kickoff) {
      this.minutaName = this.kickoff.file_name
      this.minutaUrl = this.kickoff.url
    }
    this.business.contacts?.forEach((element: ContactsInterface) => {
      this.contacts.forEach((data: ContactsInterface) => {
        if (element.position === data.position) {
          if (element.id) {
            data.id = element.id
            data._destroy = false
          }
          data.name = element.name
          data.last_name = element.last_name
          data.email = element.email
          data.phone = element.phone
        }
      })
    })
    this.business.frameworks.forEach((element: any) => {
      this.framework_selected.push(element.id)
    })
    const archi_list: { [key: string]: any } = {
      AWS: 'aws',
      Azure: 'azure',
      'Google Cloud': 'gcloud',
      'Digital Ocean': 'digital_ocean',
      Otra: 'other',
    } as const
    this.architecture.forEach((element: any) => {
      this.architecture_selected.push(archi_list[element])
    })
    this.$nextTick(function () {
      this.resizeBox('architectureDescription')
      this.resizeBox('companyDescription')
    })
    this.getHubspotId(this.business.hubspot_id)
  }
  private reUpload() {
    this.minutaName = ''
    this.dataroomsName = []
    this.datarooms = []
    this.fileToUpload = []
  }
  private previewFiles() {
    this.readbase64()
  }
  private readbase64() {
    const refFiles: any = this.$refs.filesBusiness
    const reader = new FileReader()
    reader.readAsDataURL(refFiles.files[0])
    reader.onload = () => {
      const fileAux: string = refFiles.files[0].name.toLowerCase()
      if (fileAux.split('.').pop() === 'pdf') {
        this.minutaName = fileAux
        this.dataroomsName.push(fileAux)
        const readerResult: string | undefined = reader?.result?.toString()
        if (readerResult) {
          this.datarooms.push(readerResult)
        }
        this.uploadFile()
      } else {
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Error, Tipo de archivo no permitido',
          color_notification: 'danger',
        })
        return
      }
    }
  }
  private uploadFile() {
    this.loaderUpload = true
    this.files.push({ name: this.dataroomsName[0] })
    this.fileToUpload.push({
      filename: this.dataroomsName[0],
      data: this.datarooms[0],
    })
  }
  private resizeBox(v: string) {
    const a = document.getElementById(v)
    if (a) {
      a.style.height = 'auto'
      a.style.height = a.scrollHeight + 'px'
    }
  }
  private selectedFramework(element: any) {
    this.framework_selected = element
    this.saq = (element.includes(3) || element.includes(9))
    this.frameworksAvailable = this.framework_selected.length > 0
  }
  private selectedSaq(element: any) {
    this.saq_selected = element
  }
  private selectedArchitecture(element: any) {
    this.architecture = element
  }
  private selectedChannel(element: any) {
    this.channel_selected = element
  }
  private getHubspotId (value: number) {
    this.hubspot_id = value
  }
  private watchValue(value: string) {
    this.loading = true
    this.$store.dispatch('BusinessModule/ExistBusiness', value).then((response) => {
      this.businessAvailable = response.data.available
      this.loading = false
    })
  }
  private watchHubspotId() {
    this.loading = true
    const parsedHubspotId = Number(this.hubspot_id)
    if (isNaN(parsedHubspotId)) {
      this.hubspot_id_is_number = false
      this.loading = false;
    } else if (parsedHubspotId != 0) {
      this.$store.dispatch('BusinessModule/ExistHubspotId', this.hubspot_id).then((response) => {
        this.hubspot_id_available = response.data.available
        this.hubspot_id_is_number = true
      this.loading = false
    })
    }
    this.hubspot_id_is_number = false
    this.loading = false
  }
  private clearContact(tr: ContactsInterface) {
    if (tr.id) {
      tr._destroy = true
    }
    tr.name = ''
    tr.last_name = ''
    tr.email = ''
    tr.phone = ''
  }
  private getdescription(value: string) {
    this.description = value
  }
  private getdescriptionArchitecture(value: string) {
    this.architecture_description = value
  }
private getDomain (value: string) {
    this.newDomain = value.trim()
  }
  private addDomain () {
    const fqdnPatter = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if(!fqdnPatter.test(this.newDomain.trim())) {
      this.isWrongUrl = true
    } else {
      this.isWrongUrl = false
      this.domains.push(this.newDomain.trim())
    }
    this.newDomain = ''
  }
  
  private editBusiness() {
    if (!this.businessAvailable) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Error, Nombre de empresa no disponible',
        color_notification: 'danger',
      })
      return
    }
    if (!this.frameworksAvailable) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Error, No se ha seleccionado ninguna normativa',
        color_notification: 'danger',
      })
      return
    }
    if (
      this.name === '' ||
      this.description === '' ||
      this.architecture_description === '' ||
      this.channel_selected.length === 0 ||
      this.hubspot_id === 0
    ) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Error, Por Favor llenar los campos requeridos',
        color_notification: 'danger',
      })
      return
    }

    const businessData: CrudBusiness = {
      id: this.business.id,
      name: this.name,
      description: this.description,
      documentation_level: this.documentation_level,
      architectures: this.architecture,
      architecture_description: this.architecture_description,
      framework_ids: this.framework_selected,
      saq_id: this.saq_selected,
      channels: this.channel_selected,
      kickoff_data: this.fileToUpload,
      domains: this.domains,
      hubspot_id: this.hubspot_id
    }
    this.$store.dispatch('NotificactionsModule/OpenNotification', {
      message: 'Por favor espere, Editando empresa',
    })
    this.loading = true
    this.$store
      .dispatch('BusinessModule/editBusiness', businessData)
      .then((response) => {
        this.$store.dispatch('PolimorficModule/getFrameworks')
        this.$store.dispatch('PolimorficModule/getCategories')
        this.$store.dispatch('PolimorficModule/getSaqs')
        if (response.data.company.frameworks.indexOf(this.frameworkSelected) == -1) {
          this.$store.commit(
            'PolimorficModule/SET_FRAMEWORK_SELECTED',
            response.data.company.frameworks[0]
          )
        }
        this.$mixpanel.track('Empresa Editada', {
          Name: this.name,
          Arquitectura: this.architecture,
          Normativas: this.framework_selected,
          saqs: this.saq_selected,
          Canales: businessData.channels,
        })
        this.resetVars()
        this.$router.go(-1)
        this.loading = false
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: `Empresa ${response.data.company.name} editada exitosamente`,
        })
        this.$emit('updateBreadcrumb', response.data.company.name)
      })
      .catch((error) => {
        this.loading = false
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Error al editar la companía',
        })
      })
  }
  private resetVars() {
    this.name = ''
    this.description = ''
    this.saq_selected = []
    this.architecture_description = ''
    this.contacts = ContactsForm
    this.contactsToForm = []
    this.channel_selected = []
    this.dataroomsName = []
    this.datarooms = []
    this.fileToUpload = []
    this.contacts.forEach((element) => {
      element.name = ''
      element.last_name = ''
      element.email = ''
      element.phone = ''
    })
  }
  get infoMessageInput() {
    if (!this.businessAvailable) {
      return 'Nombre de empresa no disponible'
    } else {
      return 'Campo Requerido'
    }
  }
  get infoMessageHubspotInput() {
    if (!this.hubspot_id_available) {
      return 'Hubspot ID no disponible'
    } else if(!this.hubspot_id_is_number || this.hubspot_id == 0) {
      return 'Campo Requerido'
    }
    return '';
  }
  get contactReturn(): ContactsInterface[] {
    return this.contacts.filter((item) => item.position !== 'main')
  }
}
